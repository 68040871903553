import HomeIcon from "./HomeIcon";
import SearchBar from "./SearchBar";
import Title from "./Title";
import TextVersionIcon from "./TextVersionIcon";
import HelpIcon from "./HelpIcon";

import './Header.css';

const Header = ({ data }) => {

    return (
        <>
            <div className='header'>
                <HomeIcon />
                <SearchBar />
                <Title />
                <TextVersionIcon filteredData={data} />
                <HelpIcon />
            </div>
        </>
    )
};

export default Header;