import React, { useState, useEffect } from 'react';

import { calculateTotalFiles } from '../functions/Scorecard';
import { sortVersions } from '../functions/Utils';

import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './SidePanel.css';

const SidePanel = ({data, isPending, error, selectedVersions, selectedSchemas, setSelectedVersions, setSelectedSchemas, filteredData}) => {

    const [selectedFiles, setSelectedFiles] = useState(0);
    const distinctVersions = [...new Set(data && data.scorecards.flatMap(scorecard => scorecard.vs_version) || [])];
    const distinctSchemas = [...new Set(data && data.scorecards.flatMap(scorecard => scorecard.ifc_schema) || [])];
    
    // initial values
    useEffect(() => {
        if (data) {
            setSelectedVersions(distinctVersions);
            setSelectedSchemas(distinctSchemas);
            setSelectedFiles(data && data.info.num_files);
        }
    }, [data]);

    const handleVersionChange = (version) => {
        setSelectedVersions(prev => {
            if (prev.includes(version)) {
                return prev.filter(v => v !== version);
            } else {
                return [...prev, version];
            }
        });
    };

    const handleSchemaChange = (schema) => {
        setSelectedSchemas(prev => {
            if (prev.includes(schema)) {
                return prev.filter(s => s !== schema);
            } else {
                return [...prev, schema];
            }
        });
    };

    return (
        <>
            <div className='sidepanel'>

                { isPending && <div>Loading...<CircularProgress size='1em'/></div> }

                { data && <div className='sidepanel-content'>

                    <Tooltip title='Info Tooltip'>
                        <InfoOutlinedIcon />
                    </Tooltip>
                    <br />

                    <label className='sidepanel-label' htmlFor="label1">Company</label>
                    <label id="label1">{data.tool.company ? data.tool.company.name : '-'}</label>

                    <label className='sidepanel-label' htmlFor="label2">Application</label>
                    <label id="label2">{data.tool.name}</label>

                    <label className='sidepanel-label' htmlFor="label3">Version</label>
                    <label id="label3">{data.tool.version ?? '-'}</label>

                    <label className='sidepanel-label' htmlFor="label4">Last Data Refresh</label>
                    <label id="label4">{data.info.last_evaluated ? new Date(data.info.last_evaluated).toLocaleDateString('en-GB', { year: "numeric", month: "short", day: "numeric" }) : '-'}</label>

                    <label className='sidepanel-label' htmlFor="label6">Total Evaluated File(s)</label>
                    <label id="label6">{data.info.tot_files}</label>

                    <label className='sidepanel-divider'></label>

                    <label className='sidepanel-label' htmlFor="label7">Selected Evaluated File(s)</label>
                    <label id="label7">{filteredData ? calculateTotalFiles(filteredData.scorecards) : 0}</label>

                    <label className='sidepanel-label' htmlFor="label5">Validation Service Version</label>
                    <label id="label5">{data.info.all_vs_versions && data.info.all_vs_versions.length > 0 
                        ? sortVersions(data.info.all_vs_versions).map((version, _) => (
                            <div key={version}>
                                <label >
                                    <input 
                                        id={version}
                                        type='checkbox' 
                                        name={version} 
                                        checked={selectedVersions.includes(version)}
                                        disabled={!distinctVersions.includes(version) || (selectedVersions.includes(version) && selectedVersions.length == 1)}
                                        onChange={() => handleVersionChange(version)}
                                    />&nbsp; {version}
                                </label>
                            </div>
                        )) 
                        : '-'}
                    </label>

                    <label className='sidepanel-label' htmlFor="label8">IFC Schema Version</label>
                    <label id="label8">{data.info.all_ifc_schemas && data.info.all_ifc_schemas.length > 0 
                        ? data.info.all_ifc_schemas.sort().map((schema, _) => (
                            <div key={schema}>
                                <label>
                                    <input 
                                        id={schema}
                                        type='checkbox' 
                                        name={schema} 
                                        checked={selectedSchemas.includes(schema) && distinctSchemas.includes(schema)}
                                        disabled={!distinctSchemas.includes(schema) || (selectedSchemas.includes(schema) && selectedSchemas.length == 1)}
                                        onChange={() => handleSchemaChange(schema)}
                                    />&nbsp; {schema}
                                </label>
                            </div>
                        )) 
                        : '-'}
                    </label>
                    
                    </div>}

            </div>
        </>
    )
};

export default SidePanel;
