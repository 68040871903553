import React from 'react';

import { aggregateScorecards } from '../functions/Scorecard';

import Scorecard from './Scorecard';
import CircularProgress from '@mui/material/CircularProgress';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';

import './ScorecardGrid.css';

const ScorecardGrid = ({
    data,
    isPending,
    error,
    onItemClick
}) => {
    
    const aggregated = data && aggregateScorecards(data.scorecards);

    return (
        <div className='scorecardgrid'>
            {isPending && <div>Loading... <CircularProgress size='1em'/></div>}
            {error && !error.includes('404') && <div>ERROR: {error}</div>}
            {error && error.includes('404') && <div>
                <SearchOffOutlinedIcon color="disabled" fontSize='medium' />
                Scorecard for this Authoring Tool was not found (404: Not Found)
            </div>}
            
            <div className='scorecardgrid-content'>                
                {aggregated && aggregated.map((card, index) => (
                    <Scorecard
                        id={index}
                        key={index}
                        onClick={onItemClick}
                        {...card}
                    />
                ))}
            </div>
        </div>
    );
};

export default ScorecardGrid;