import React, { useState } from 'react';
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import { SEVERITY_TO_ICON, SEVERITY_TO_COLOR } from '../utils/Constants';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { IconButton } from '@mui/material';

import './Scorecard.css';

const Scorecard = ({ id, code, severity, children, isChild, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    const url = `${FETCH_PATH}/functionalpart`;
    const { data, loading, error } = useFetchJson(url);
    
    const handleToggle = () => {

        setExpanded(!expanded);
    };

    function getFuncPart(code) {

        return data && data.filter(fp => fp.id === code)[0];
    }
    
    return (data && 
        <div style={{
            gridColumn: 'span ' + getFuncPart(code)?.span,
            ...(expanded ? {border: 'dotted 1px gray'} : {})
        }}>
            <div className={`scorecard ${expanded ? ' expanded' : ''}`} onClick={() => {onClick(code)}} style={{
                cursor: 'pointer',
                ...(expanded ? {} : {backgroundColor: SEVERITY_TO_COLOR[severity]}),
                ...(isChild || expanded ? {marginBottom: '15px'} : {})
            }}>
                { children && children.length > 0 && 
                    
                        <IconButton onClick={handleToggle} style={{float: "right"}}>
                            <Tooltip title={expanded ? 'click to collapse': 'click to expand' } placement="top">
                                {expanded ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon /> }
                            </Tooltip>
                        </IconButton>
                }
                <Tooltip title={getFuncPart(code)?.description} placement="top">
                    <span>
                        { code && <span className="scorecard-code">{SEVERITY_TO_ICON[severity]} {code}</span> }
                        <span className="scorecard-title">{getFuncPart(code)?.name}</span>
                    </span>
                </Tooltip>
            </div>
            { expanded && children && children.length > 0 && children.map((child, index) => (
                <Scorecard {...child} isChild={true} key={`${id}/${index}`} onClick={onClick} />
            ))}
        </div>
    )
};

export default Scorecard;