import { React } from 'react';
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import { aggregateScorecards } from '../functions/Scorecard';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './DetailPanel.css';

const DetailPanel = ({code, scorecards, onClose}) => {

    const url = `${FETCH_PATH}/functionalpart`;
    const { data, loading, error } = useFetchJson(url);

    function getFailingRules() {

        let rules = Object.entries(item.failing_rules || {});
        if (item.children) {
            item.children.map((c, _) => rules = rules.concat(Object.entries(c.failing_rules || {})));
        }
        return rules;
    }

    function getWarningRules() {

        let rules = Object.entries(item.warning_rules || {});
        if (item.children) {
            item.children.map((c, _) => rules = rules.concat(Object.entries(c.warning_rules || {})));
        }
        return rules;
    }

    function getPassingRules() {
        // @nb Executed is mapped to Passing in the UI, because actually, in the gherkin rules after_feature logic,
        // Executed outcomes are only persisted to the DB when there no errors and warnings.
        let rules = Object.entries(item.executed_rules || {});
        if (item.children) {
            item.children.map((c, _) => rules = rules.concat(Object.entries(c.executed_rules || {})));
        }
        return rules;
    }

    function getNARules() {
        let rules = Object.entries(item.na_rules || {});
        if (item.children) {
            item.children.map((c, _) => rules = rules.concat(Object.entries(c.na_rules || {})));
        }
        return rules;
    }

    function getFuncPart(code) {
        
        return data && data.filter(fp => fp.id === code)[0];
    }

    const aggregated = scorecards && aggregateScorecards(scorecards);
    const flat = scorecards && aggregated.concat(aggregated.flatMap(c => c.children || []));
    var item = (flat && code ? flat.filter(a => a.code === code)[0] : null);

    return (
        <div className='detailpanel' style={{display: item ? "block" : "none"}}>

            {item && 
            <>
                <IconButton aria-label="close" onClick={onClose} style={{float: "right"}}>
                    <CloseIcon />
                </IconButton>
                <h2>{code} <span style={{fontWeight: 100}}>{getFuncPart(code)?.name}</span></h2>
                <span>
                    {getFuncPart(code)?.description}
                </span>
                {getFailingRules().length > 0 && <>
                <div>
                    <h3>Failing rules</h3>
                    <ul>
                        {getFailingRules().sort().map(([code, n]) => <li key={'f_'+code}>{code} <i>({n.toLocaleString()} {n > 1 ? 'files' : 'file' })</i></li>)}
                    </ul>
                </div>
                </>}
                {getWarningRules().length > 0 && <>
                <div>
                    <h3>Warning rules</h3>
                    <ul>
                        {getWarningRules().sort().map(([code, n]) => <li key={'w_'+code}>{code} <i>({n.toLocaleString()} {n > 1 ? 'files' : 'file' })</i></li>)}
                    </ul>
                </div>
                </>}
                {getPassingRules().length > 0 && <>
                <div>
                    <h3>Passing rules</h3>
                    <ul>
                        {getPassingRules().sort().map(([code, n]) => <li key={'p_'+code}>{code} <i>({n.toLocaleString()} {n > 1 ? 'files' : 'file' })</i></li>)}
                    </ul>
                </div>
                </>}
                {getNARules().length > 0 && <>
                <div>
                    <h3>Not applicable rules</h3>
                    <ul>
                        {getNARules().sort().map(([code, n]) => <li key={'n_'+code}>{code} <i>({n.toLocaleString()} {n > 1 ? 'files' : 'file' })</i></li>)}
                    </ul>
                </div>
                </>}
            </>}
        </div>
    )
};

export default DetailPanel;