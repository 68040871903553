import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';
import { SEVERITY_TO_TEXT_LABEL } from '../utils/Constants';
import { calculateTotalFiles  } from '../functions/Scorecard';

import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './TextVersionIcon.css';

const TextVersionIcon = ({ filteredData }) => {

    const [processing, setProcessing] = useState(false);

    const url = `${FETCH_PATH}/functionalpart`;
    const { data, loading, error } = useFetchJson(url);

    function getFuncPart(code) {
        
        return data && data.filter(fp => fp.id === code)[0];
    }

    function getFileName() {

        let filename = `scorecard_${filteredData.tool.company.name}_${filteredData.tool.name}_${filteredData.tool.version}.txt`;

        // TODO: be less strict with filename encoding?
        // eg. use JS equivalent of urllib.parse.quote(file_name, safe=' ()[]')
        filename = encodeURIComponent(filename);

        return filename;
    }

    function getFileContents() {

        const HEADER_TEMPLATE = `
            ==================================================
            Company: {COMPANY_NAME}
            Application: {TOOL_NAME}
            Version: {TOOL_VERSION}
            Last Data Refresh: {LAST_DATA_REFRESH}
            Total Evaluated File(s): {TOTAL_EVALUATED_FILES}
            Validation Service Version: {VS_VERSION}
            IFC Schema Version: {SCHEMA_VERSION}
            Selected File(s): {SELECTED_FILES}
            ==================================================
        `;

        const distinctVersions = [...new Set(filteredData.scorecards.flatMap(scorecard => scorecard.vs_version) || [])].sort();
        const distinctSchemas = [...new Set(filteredData.scorecards.flatMap(scorecard => scorecard.ifc_schema) || [])].sort();

        let file_content = '';
        distinctVersions.forEach(version => {
            distinctSchemas.forEach(schema => {

                // apply version/schema filter
                let scorecards = filteredData.scorecards.filter(s => s.vs_version === version && s.ifc_schema === schema);
                if(!scorecards)
                    return;

                // header
                let header = HEADER_TEMPLATE.split('\n')
                    .map(line => line.trim())
                    .filter(line => Boolean(line))
                    .join('\n');

                header = header.replace('{COMPANY_NAME}', filteredData.tool.company.name);
                header = header.replace('{TOOL_NAME}', filteredData.tool.name);
                header = header.replace('{TOOL_VERSION}', filteredData.tool.version);
                header = header.replace('{LAST_DATA_REFRESH}', new Date(filteredData.info.last_evaluated).toLocaleDateString('en-GB', { year: "numeric", month: "short", day: "numeric" }));
                header = header.replace('{TOTAL_EVALUATED_FILES}', filteredData.info.tot_files);
                header = header.replace('{VS_VERSION}', version);
                header = header.replace('{SCHEMA_VERSION}', schema);
                header = header.replace('{SELECTED_FILES}', calculateTotalFiles(scorecards));

                // scorecard & rules
                let sc_content = '';
                scorecards.forEach(sc => {

                    sc_content += `${sc.code} ${getFuncPart(sc.code)?.name}: ${SEVERITY_TO_TEXT_LABEL[sc.severity]}\n`

                    let rules_set = Object.entries({
                        'failing_rules': 'fail',
                        'warning_rules': 'warning',
                        'executed_rules': 'pass',
                        'na_rules': 'not applicable',
                    });

                    // iterate rules
                    rules_set.forEach(set => {
                        let rules = Object.entries(sc[set[0]] || {});
                        rules.forEach(rule => {
                            sc_content += `    ${rule[0]}: ${set[1]} (${rule[1]})\n`;
                        });                        
                    });

                    // iterate children
                    sc.children?.forEach(child => {
                        sc_content += `    ${child.code} ${getFuncPart(child.code)?.name}: ${SEVERITY_TO_TEXT_LABEL[child.severity]}\n`

                        // iterate child rules
                        rules_set.forEach(set => {
                            let rules = Object.entries(child[set[0]] || {});
                            rules.forEach(rule => {
                                sc_content += `        ${rule[0]}: ${set[1]} (${rule[1]})\n`;
                            });                        
                        });
                    });
                });

                //
                file_content += header + '\n\n' + sc_content + '\n\n'; 
            })
        });

        return file_content;
    }

    const onLinkClick = async () => {

        // show progress spinner
        setProcessing(true);

        try {

          // determine contents, filename, and content type
          const file_contents = getFileContents();
          const file_name = getFileName();
          const content_type = 'text/plain';

          // create blob and URL
          const blob = new Blob([file_contents], { type: content_type });
          const url = URL.createObjectURL(blob);

          // create link and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = file_name;
          link.click();
        }
        catch (error) {

          console.error(error);
        }
        finally {

          setProcessing(false);
        }
    };

    return (
        <>
            <div className='textversionicon'>
                { filteredData &&
                    <Tooltip title='Download a textual summary of this Scorecard'>
                        
                            {processing ? <CircularProgress size={20} /> : <Link onClick={onLinkClick} href='#'><FileDownloadOutlinedIcon /></Link>}
                        
                    </Tooltip>
                }
            </div>
        </>
    )
};

export default TextVersionIcon;