import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import WarningIcon from '@mui/icons-material/Warning';


export const SEVERITY_TO_LABEL = 
{
    0: "N/A",
    1: "Executed",
    2: "Passed",
    3: "Warning",
    4: "Error",
};

export const SEVERITY_TO_TEXT_LABEL = 
{
    0: "not applicable",
    1: "pass",
    2: "pass",
    3: "warning",
    4: "fail",
};

export const SEVERITY_TO_COLOR = 
{
    0: "#dddddd", // N/A
    1: "rgb(217, 242, 217)", // executed
    2: "rgb(217, 242, 217)", // passed
    3: "rgb(253, 253, 150)", // warning
    4: "rgb(255, 204, 204)", // error
};

export const SEVERITY_TO_ICON = {
    "0": <BrowserNotSupportedIcon color="disabled" />,
    "1": <CheckCircleIcon sx={{ color: "#2ab672" }} />,
    "2": <CheckCircleIcon sx={{ color: "#2ab672" }} />,
    "3": <WarningIcon color="warning" />,
    "4": <ErrorIcon color="error" />,
  }
  