  export function sortVersions (versions = []) {

    // code based on:
    // https://gist.github.com/luan0ap/4ab9447ca581e7daabfd15fa789e7e46
    const sortByVersionNumber = (vA, vB) => {
      const versionsA = vA.split('.')
      const versionsB = vB.split('.')
  
      const compareVersionNumbers = (index = 0, lastResult = 0) => {
        const versionNumberA = Number(versionsA[index]) || 0
        const versionNumberB = Number(versionsB[index]) || 0
  
        if (lastResult === 0) {
          if (versionNumberA > versionNumberB) {
            return compareVersionNumbers(index + 1, 1)
          }
    
          if (versionNumberA < versionNumberB) {
            return compareVersionNumbers(index + 1, -1)
          }
            
          if (versionNumberA === versionNumberB) {
            return compareVersionNumbers(index + 1, 0)
          }
        }
  
        return lastResult
      }
  
      return compareVersionNumbers()
    }
  
    return versions.sort(sortByVersionNumber)
};