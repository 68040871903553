const ruleTypes = ['failing_rules', 'warning_rules', 'passing_rules', 'na_rules', 'executed_rules'];

export function mergeRules(sourceRules = [], targetRules = []) {
    
    const mergedRules = { ...sourceRules };

        for (const [rule, count] of Object.entries(targetRules)) {

            mergedRules[rule] = (mergedRules[rule] || 0) + count;
        }

        return mergedRules;
};

export function mergeChildren(sourceChildren = [], targetChildren = []) {

    const mergedChildren = [...sourceChildren];

    for (const targetChild of targetChildren) {

        const sourceChildIndex = mergedChildren.findIndex(child => child.code === targetChild.code);

        if (sourceChildIndex !== -1) {

            mergedChildren[sourceChildIndex] = mergeScorecards(mergedChildren[sourceChildIndex], targetChild);

        } else {

            mergedChildren.push(targetChild);
        }
    }

    return mergedChildren;

};

export function mergeScorecards(source, target) {
    
    if (source.code !== target.code) {
        throw new Error('Cannot join two scorecards with different codes')
    }

    const mergedCard = { ...source };
    
    mergedCard.severity = Math.max(target.severity, source.severity);
    mergedCard.children = mergeChildren(source.children, target.children)

    ruleTypes.forEach(ruleType => {
        mergedCard[ruleType] = mergeRules(target[ruleType], source[ruleType]);
    });
    
    return mergedCard;
};

export function aggregateScorecards(scorecards) {

    if (!Array.isArray(scorecards)) {
        return [];
    }

    const aggregatedMap = scorecards.reduce((acc, scorecard) => {

        const { code } = scorecard;
        
        if (!acc.has(code)) {
            
            const initializedCard = {
                ...scorecard,
                children: scorecard.children || []
            };
            
            ruleTypes.forEach(ruleType => {
                initializedCard[ruleType] = scorecard[ruleType] || [];
            });
            
            acc.set(code, initializedCard);
        } else {
            const existing = acc.get(code);
            acc.set(code, mergeScorecards(scorecard, existing));
        }
        
        return acc;
    }, new Map());

    return Array.from(aggregatedMap.values());
};

export function calculateTotalFiles(scorecards) {

    var all_cards = scorecards.concat(scorecards.flatMap(c => c.children || []));
    var all_rules = all_cards.flatMap(c => [ c.executed_rules, c.failing_rules, c.warning_rules, c.passing_rules, c.na_rules ]).reduce((a, b) => a.concat(b), []);
    all_rules = all_rules.filter( value => value && Object.keys(value).length !== 0);

    const summedRules = all_rules.reduce((acc, curr) => {
        const key = Object.keys(curr)[0];
        acc[key] = (acc[key] || 0) + curr[key];
        return acc;
    }, {});

    const total = Object.values(summedRules).reduce((r1, r2) => Math.max(r1, r2), 0);
    return total;
};