import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';
import { sortVersions } from '../functions/Utils';

import CircularProgress from '@mui/material/CircularProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

import Header from '../components/Header';
import Footer from '../components/Footer';

import './Dashboard.css';

const Dashboard = () => { 

  const [ url ] = useState(`${FETCH_PATH}/authoringtool`);
  const { data, isPending, error } = useFetchJson(url);

  const distinctVersions = [...new Set(data && data.flatMap(tool => tool.models.filter(model => model.vs_version_excluded !== true).flatMap(model => model.vs_version) || []) || [])];

  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState([]);

  const navigate = useNavigate();

  // initial values
  useEffect(() => {
      if (data) {
        setSelectedVersions(distinctVersions);
      }
  }, [data]);

  const CustomTreeItem = ({ item, onClick, onContextMenu }) => {
  
    return (
      <TreeItem
        itemId={item.nodeId}
        label={item.label}
        onClick={(event) => onClick(event, item)}
        onContextMenu={(event) => onContextMenu(event, item)}
      >
  
        {item.children &&
          item.children.map((child) => (
            <CustomTreeItem
              key={child.nodeId}
              item={child}
              onClick={onClick}
              onContextMenu={onContextMenu}
            />
          ))}
      </TreeItem>
    );
  };

  const handleContextMenu = (event, item) => {

    event.preventDefault();
    event.stopPropagation();

    if (item?.url) {

      // ctrl/option + left-click --> opens in new window/tab
      if ((event.metaKey || event.ctrlKey) && event.button === 0) {

        window.open(item.url, "_blank");

      // left-click --> opens in same window/tab
      } else if (!event.ctrlKey && event.button === 0) {

        navigate(item.url);
        navigate(0); // hack
      }
      else if (event.button === 2) {

        setMenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
        setSelectedItem(item);
      }
    }
  };

  const handleContextMenuClose = () => {
    setMenuPosition(null);
    setSelectedItem(null);
  };

  const handleContextMenuOpen = (event) => {
    
    if (selectedItem?.url) {
      window.open(selectedItem.url, "_blank");
    }
    handleContextMenuClose();
  };

  function getFilesSuffix(count) {
    return count.toLocaleString() + ((count > 1) ? ' files' : ' file');
  }

  function getProducts(data) {

    let grouped = [];

    // dummy for items without company
    var filteredContent = data.map((el) => {
      if (el.company == null) {
        el.company = { name: '-Unknown Vendor-' };
      }

      // filter by version
      el.filtered_models = (el.models || []).filter((model) => {
        return selectedVersions.includes(model.vs_version) && model.vs_version_excluded !== true;
      });

      // filter is_vendor and is_staff and quarantined
      el.filtered_models = el.filtered_models.filter((model) => {
        return model.is_vendor !== true && model.is_staff !== true && model.quarantined !== true;
      });

      return el;
    });

    // remove excluded companies/tools
    filteredContent = filteredContent.filter((el) => {
      return el.excluded !== true && el.company?.excluded !== true;
    });

    // remove items without any hits
    filteredContent = filteredContent.filter((el) => {
      return el.filtered_models?.length
    });

    // order all tools
    filteredContent.sort((f1, f2) => f1.company.name > f2.company.name ? 1 : -1);

    for (let c of (filteredContent || [])) {
      if (grouped.length === 0 || (c.company.name) !== grouped[grouped.length-1][0]) {
        grouped.push([c.company.name,[]])
      }
      grouped[grouped.length-1][1].push(c);
    }

    // order companies
    grouped.sort((c1, c2) => c1[0].toLowerCase() > c2[0].toLowerCase() ? 1 : -1);

    grouped = grouped.map((value, index) => {

      return {

        // company
        id: (-index).toString(), // prevent clash with tool id
        nodeId: (-index).toString(),
        label: value[0] + ' - ' + getFilesSuffix(value[1].reduce( (a, b) => {
            return a + (b.filtered_models?.length || 0);
        }, 0)),
        children: value[1].sort((t1, t2) => t1.name > t2.name ? 1 : -1).map((value2) => {
        
          // tool
          return {
            id: value2.id.toString(),
            nodeId: value2.id.toString(),
            url: `../scorecard/${value2.id}`,
            label: value2.name + ' - ' + value2.version + ' - ' + getFilesSuffix(value2.filtered_models?.length || 0)
          }
        
        })
      }
    });

    return grouped;
  }

  const handleVersionChange = (version) => {
    setSelectedVersions(prev => {
        if (prev.includes(version)) {
            return prev.filter(v => v !== version);
        } else {
            return [...prev, version];
        }
    });
};

  return (

    <>
      <div className='dashboard-fullpage'>
        <Header />
        <div className='dashboard-content'>
        
          { isPending && <div>Loading... <CircularProgress size='1em'/></div> }
          { error && <div>{error}</div> }
          { data && <h4>Validation Service Version</h4> }
          { data && distinctVersions && distinctVersions.length > 0 
                ? sortVersions(distinctVersions).map((version, _) => (
                    <div key={version}>
                        <label >
                            <input 
                                id={version}
                                type='checkbox' 
                                name={version} 
                                disabled={!distinctVersions.includes(version) || (selectedVersions.includes(version) && selectedVersions.length == 1)}
                                checked={selectedVersions.includes(version)}
                                onChange={() => handleVersionChange(version)}
                            />&nbsp; {version}
                        </label>
                    </div>
                )) 
                : '-'}
          { data && <h4>Authoring Tools</h4> }
          { data && <SimpleTreeView>
              {getProducts(data).map((item) => (
                <CustomTreeItem
                  key={item.id}
                  item={item}
                  onClick={handleContextMenu}
                  onContextMenu={handleContextMenu}
                />
              ))}
            </SimpleTreeView> }

          <Menu
            open={!!menuPosition}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              menuPosition !== null
                ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleContextMenuOpen}>Open in new tab...</MenuItem>
          </Menu>

        </div>
        <Footer />
      </div>
    </>
    
  );
};

export default Dashboard;
