import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useFetchJson } from '../hooks/useFetchJson';
import { FETCH_PATH } from '../utils/Environment';

import Header from '../components/Header';
import SidePanel from '../components/SidePanel';
import ScorecardGrid from '../components/ScorecardGrid';
import Legend from '../components/Legend';
import Footer from '../components/Footer';
import DetailPanel from '../components/DetailPanel'

import './Scorecard.css';

const Scorecard = () => { 

  // fetch id from route parameters
  const { id } = useParams();
  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const version = params.get('version');
  const schema = params.get('schema');
  const vendor = params.get('vendor');
  const staff = params.get('staff');
  
  // TODO: use url builder?
  const [ url ] = useState(`${FETCH_PATH}/scorecard/${id}?version=${version}&schema=${schema}&vendor=${vendor}&staff=${staff}`
      .replace('version=null','')
      .replace('schema=null', '')
      .replace('vendor=null', '')
      .replace('staff=null', '')
      .replaceAll('&&', '&')
      .replace('?&', '?')
      .replace(/\?$/, ''));
  
  const { data, isPending, error } = useFetchJson(url);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [selectedSchemas, setSelectedSchemas] = useState([]);

  const handleItemClick = (item) => {
      setSelectedCode(item);
  };

  const filteredData = React.useMemo(() => {
    if (!data || selectedSchemas.length === 0 || selectedVersions.length === 0) return null;
    
    return {
      ...data,
      scorecards: data.scorecards.filter(scorecard => 
        selectedSchemas.includes(scorecard.ifc_schema) && selectedVersions.includes(scorecard.vs_version)
      )
    };
  }, [data, selectedVersions, selectedSchemas]);

  return (

    <>
      <div className='fullpage'>
        <Header data={filteredData} />
        <div className='content'>
          <SidePanel 
            data={data} 
            isPending={isPending} 
            error={error}
            selectedVersions={selectedVersions}
            selectedSchemas={selectedSchemas}
            setSelectedVersions={setSelectedVersions}
            setSelectedSchemas={setSelectedSchemas}
            filteredData={filteredData}
          />
          <div className='inner-content'>
            <ScorecardGrid data={filteredData} isPending={isPending} error={error} onItemClick={handleItemClick} />
            <DetailPanel code={selectedCode} scorecards={filteredData?.scorecards} onClose={() => {handleItemClick(null);}} />
            {data && !isPending && <Legend />}
          </div>
        </div>
        <Footer />
      </div>
    </>
    
  );
};

export default Scorecard;
